<template>
  <div class="signature-details">
    <div class="signature-details__content">
      <div
        class="signature-details__row"
        v-if="signature.bookingRegistrationCode"
      >
        <span> {{ $t("Booking reference") }}</span>
        <span class="signature-details__link" @click="handleBookingClick">
          {{ signature.bookingRegistrationCode }}
        </span>
      </div>
      <div
        v-for="(field, index) in sortedFields"
        :key="`field-${index}`"
        class="signature-details__row"
      >
        <span>{{ field.label }}</span>
        <span>
          {{ getFieldValue(field) }}
        </span>
      </div>
      <div
        v-for="(checkbox, index) in signature.checkboxes"
        :key="`checkbox-${index}`"
        class="signature-details__row"
      >
        <span v-html="sanitize(checkbox.text)" />
        <span>
          {{ checkbox.value ? "Yes" : "No" }}
        </span>
      </div>
      <div class="signature-details__row">
        <span>{{ $t("Time of signing") }}</span>
        <span>
          {{ formattedDate }}
        </span>
      </div>
      <div class="signature-details__row">
        <span>{{ $t("Signature file") }}</span>
        <span class="signature-details__link" @click="handleSignatureFileClick">
          {{ $t("View") }}
        </span>
      </div>
    </div>
    <div class="signature-details__buttons">
      <Button
        class="signature-details__button"
        variant="primary"
        is-small
        :is-block="['xs', 'sm'].includes($mq)"
        @click="handleSignatureExport"
      >
        {{ $t("Raw export to CSV") }}
      </Button>
      <Guard permission="signatures.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable"
          class="signature-details__button"
          :is-loading="isDeleting"
          variant="danger"
          is-small
          is-outlined
          :is-block="['xs', 'sm'].includes($mq)"
          @click="handleSignatureDelete"
        >
          {{ $t("Delete Signature") }}
        </Button>
      </Guard>
    </div>
    <SignatureDetailFileModal :signature="signature.signature" />
  </div>
</template>

<script>
import SignatureDetailFileModal from "@/components/signatures/SignatureDetailFileModal";
import moment from "moment";
import {
  getTimezoneOffsetHours,
  getVenueTimeFormat,
  sanitize,
} from "@/helpers/utils";
import { mapActions, mapState } from "vuex";
import Guard from "@/components/common/Guard";
import dialog from "@/plugins/dialog";

export default {
  name: "SignatureDetails",
  components: { Guard, SignatureDetailFileModal },
  props: {
    signature: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
    formattedDate() {
      const timeFormat = getVenueTimeFormat();
      const dateMoment = moment.utc(this.signature.createdAt.seconds, "X");
      return dateMoment
        .add(getTimezoneOffsetHours(this.venue.timezone), "h")
        .format(`${timeFormat}, MMM D, YYYY`);
    },
    sortedFields() {
      return Object.values(this.signature.fields)
        .filter((field) => !field.isDisabled)
        .sort((a, b) => a.sortWeight - b.sortWeight);
    },
  },
  methods: {
    ...mapActions({
      deleteSignature: "signatures/deleteSignature",
      exportSignaturesToCSV: "signatures/exportSignaturesToCSV",
    }),
    sanitize,
    handleBookingClick() {
      this.$router.push({
        name: "BookingDetail",
        params: {
          id: this.signature.bookingId,
        },
      });
    },
    handleSignatureFileClick() {
      this.$modal.show("signature-detail-file-modal");
    },
    getFieldValue(field) {
      if (Array.isArray(field.value)) {
        return field.value.join(", ");
      }
      if (typeof field.value === "boolean") {
        return field.value ? "Yes" : "No";
      }
      return field.value;
    },
    async handleSignatureDelete() {
      const confirmed = await dialog.confirm({
        title: "Delete Signature?",
        message: "All signature data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteSignature(this.signature.id);
          await this.$router.push({
            name: "Waivers",
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.isDeleting = false;
        }
      }
    },
    handleSignatureExport() {
      try {
        this.exportSignaturesToCSV([this.signature]);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-details {
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 100%;
  width: 100%;

  &__content {
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    padding: 24px 16px;

    @media (min-width: $media-laptop) {
      padding: 24px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $secondary-500;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__link {
    font-weight: 500;
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-top: auto;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      gap: 24px 20px;
    }
  }
}
</style>
