<template>
  <div class="signature-details-page">
    <BackTitle @click="goBack">Signature details</BackTitle>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <div class="empty" v-else-if="isError">
      {{ $t("An error occurred") }}. {{ $t("Repeat the request later") }}
    </div>
    <SignatureDetails v-else :signature="signature" />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import SignatureDetails from "@/components/signatures/SignatureDetails";
import { mapActions, mapState } from "vuex";
import dialog from "@/plugins/dialog";

export default {
  components: { SignatureDetails, BackTitle },
  data() {
    return {
      isLoading: false,
      isError: false,
    };
  },
  computed: {
    ...mapState({
      signature: (state) => state.signatures.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchSignatureById(this.$route.params.id);
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchSignatureById: "signatures/fetchSignatureById",
      deleteSignature: "signatures/deleteSignature",
      exportSignaturesToCSV: "signatures/exportSignaturesToCSV",
    }),
    goBack() {
      this.$router.push({
        name: "Waivers",
      });
    },
    async handleSignatureDelete() {
      const confirmed = await dialog.confirm({
        title: "Delete Signature?",
        message: "All signature data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteSignature(this.signature.id);
          await this.$router.push({
            name: "Waivers",
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.isDeleting = false;
        }
      }
    },
    handleSignatureExport() {
      try {
        this.exportSignaturesToCSV([this.signature]);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-details-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
