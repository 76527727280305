<script>
import { mapGetters } from "vuex";

export default {
  name: "Guard",
  props: {
    permission: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    isAvailable() {
      return !this.permission || this.checkPermission(this.permission);
    },
  },
  render() {
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        isAvailable: this.isAvailable,
      });
    }
    return null;
  },
};
</script>
