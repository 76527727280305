<template>
  <div class="back-title">
    <Icon name="arrow-back" :color="color" isClickable @click="handleClick" />
    <Title class="title" :level="level" :color="color">
      <slot />
    </Title>
    <div v-if="$slots.append" class="back-title__append">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
import dialog from "@/plugins/dialog";

export default {
  name: "BackTitle",
  props: {
    text: String,
    color: {
      type: String,
      default: "secondary-500",
    },
    confirmClick: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 2,
    },
  },
  methods: {
    async handleClick() {
      if (!this.confirmClick || (await this.confirm())) {
        this.$emit("click");
      }
    },
    confirm() {
      return dialog.confirm({
        title: `Are you sure?\nChanges will be lost`,
        okText: "Continue without saving",
        cancelText: "Back",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-title {
  position: relative;
  width: 100%;
  height: min-content;
  display: flex;
  align-items: center;

  .title {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    pointer-events: none;
  }

  &__append {
    margin-left: auto;
  }
}
</style>
