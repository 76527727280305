<template>
  <custom-modal width="87%" :name="name" classes="SignatureDetailFileModal">
    <div class="SignatureDetailFileModal__close">
      <Icon name="close" is-clickable @click="$modal.hide(name)" />
    </div>
    <div class="SignatureDetailFileModal__signatureWrapper">
      <img
        :src="signature"
        alt="signature image"
        class="SignatureDetailFileModal__signature"
      />
    </div>
  </custom-modal>
</template>
<script>
export default {
  name: "SignatureDetailFileModal",
  props: {
    signature: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    name: "signature-detail-file-modal",
  }),
};
</script>
<style lang="scss">
.SignatureDetailFileModal {
  border-radius: 20px;
  padding: 16px 16px 40px;
  display: flex;
  flex-direction: column;

  &__close {
    margin-left: auto;
  }

  &__signatureWrapper {
    background: rgba(40, 110, 237, 0.04);
    border: 1px solid rgba(40, 110, 237, 0.19);
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
  }

  &__signature {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
